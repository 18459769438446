<template>
  <div>
    <ys-page-list
      ref="table"
      :action="companyId ? 'org/user/ListByOrg' : 'manage/user/List'"
      :params="params"
      :tableHeight="height-300"
      :headerColumns="headerColumns"
      :isEmptySelect="isEmptySelect"
      @on-selection-change="checkItem"
    >
      <template slot="search">
        <FormItem label="关键词" prop="keyword">
          <Input placeholder="请输入专家姓名或电话" style="width: 120px;" v-model="params.keyword"></Input>
        </FormItem>
      </template>
    </ys-page-list>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="ok">确定</Button>
      <Button @click="close">取消</Button>
    </div>
  </div>
</template>

<script>
import pageList from '@/mixins/pageList.js'

export default {
  name: '',
  mixins: [pageList],
  props: {
    limit: {
      type: Number,
      default: 0
    },
    IDs: {
      type: Array,
      default: () => []
    },
    companyId: {
      type: [Number, String]
    }
  },
  computed: {
    isEmptySelect() {
      return this.limit === 1;
    },
  },
  data() {
    return {
      headerColumns: [
        {type: 'selection', title: '选择框', width: 60, align: 'center'},
        {minWidth: 120, title: '账号', key: 'username', align: 'center'},
        {minWidth: 100, title: '姓名', key: 'iname', align: 'center'},
        {minWidth: 180, title: '所在部门', key: 'depName', align: 'center'},
        {width: 210, title: 'email', key: 'mail', align: 'center'},
        {width: 140, title: '电话', key: 'phone', align: 'center'},
        {width: 130, title: '角色', key: 'roleName', align: 'center'},
        {width: 130, title: '职位', key: 'position', align: 'center'},
        {width: 100, title: '是否在职', key: 'jobStatus', align: 'center', renderConfig: {type: 'baseData', parentName: '是否'}},
      ],
      params: {
        orgId: '',
        depId: '',
        keyword: '',
      },
      modal: {}
    }
  },

  mounted() {
    this.params.orgId = this.companyId || this.userInfo.org_id;
    this.getList();
    this.modal = this.$parent.$parent;
  },

  methods: {
    close() {
      this.modal.modShow = false;
    },

    ok() {
      if (this.tableSelectItem.length === 0) {
        this.$Notice.warning({
          title: "操作提示！",
          desc: "请选择至少一条数据！"
        });
        return false;
      }
      if (this.limit !== 0) {
        if (this.tableSelectItem.length > this.limit) {
          this.$Notice.warning({
            title: "操作提示！",
            desc: "最多可选择 " + this.limit + ' 条数据！',
          });
          return false;
        }
      }
      this.$emit('on-ok', this.tableSelectItem);
      this.close();
    }
  }



}
</script>
